import { Dmenu } from "./dmenu.js"; //ドロワーメニュー
import { Env } from "./env.js"; //ブラウザなど環境チェック
//import { youtubeLazy } from './youtube-lazyload.js';//Youtube遅延(スクロール到達時)読み込み
import { ScrChecker } from "./scroll-checker-simple.js";
import { Acc } from "./accordion.js"; //アコーディオン
//import { Swp } from "./swp.js"; //Swiper
import { Opening } from "./opening.js"; //オープニング関連
//import { Urlparam } from './urlparam.js';// URLパラメータ
//import { Tab } from "./tab.js"; //タブ切り替え
//import { SizeInt } from './sizeint.js';//サイズを整数に

//import { Modal } from "./class/modal-window.js";
//import MicroModal from "micromodal";

import { PageAncher } from "./page-ancher.js"; //ページ内アンカーリンク（スムーズスクロール）

import smoothscroll from "smoothscroll-polyfill";
// https://www.npmjs.com/package/smoothscroll-polyfill
// http://iamdustan.com/smoothscroll/

// window.scroll smooth not working on Safari
// https://stackoverflow.com/questions/51731754/window-scroll-smooth-not-working-on-safari
// Window.scroll() https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll

export class Main {
  constructor() {
    this.timerResized = false; //resize時
    this.timerScrolled = false; //scroll時
    this._click = "";
    this.env = {};
    //this.youtubeLazy = {};
    this.loadedFlg = false;
    this.scrChecker = {};
    this.pageAncher = {};
    //this.accordions = {};
    this.sliders = {};
    this.openingManager = {};
    //this.tabs = {};
    //this.sizeint= {};
    this.resize = {};
    this.dmenu = {};
  }

  init() {
    //console.log('init');
    //this.scrCheck = new ScrPos();
    this.body = document.getElementsByTagName("body")[0];
    this.html = document.getElementsByTagName("html")[0];

    // DOMContentLoaded時
    // ------------------------------
    window.addEventListener("DOMContentLoaded", () => {
      // ブラウザOS環境
      this.env = new Env();
      this.env.detectEnv();

      // スクロールチェッカー
      this.scrChecker = new ScrChecker();
      this.scrChecker.init();

      //page ancher
      smoothscroll.polyfill();
      this.pageAncher = new PageAncher();
      this.pageAncher.init();

      //SizeInt
      //this.sizeint = new SizeInt();

      // Resize
      //this.resize = new ResizeElem();

      // モーダルウインドウ
      //   var modal = new Modal();
      //   modal.init();
      //MicroModal.init();

      // スクロール時
      window.addEventListener("scroll", () => {
        //console.log(this.env.browser.ie11);
        this.scrChecker.onUpdate();
      });

      // ウインドウリサイズ時
      window.addEventListener("resize", () => {
        this.scrChecker.onUpdate();
        if (this.timerResized !== false) {
          clearTimeout(this.timerResized);
        }
        this.timerResized = setTimeout(() => {
          //this.accordions.accResize();
          //this.resize.resizing();
        }, 700);
      });

      // dmenu
      this.dmenu = new Dmenu();
      this.dmenu.init();

      // アコーディオン
      this.accordions = new Acc();
      this.accordions.init();

      // Openingチェッカー
      this.openingManager = new Opening();
      this.openingManager.init();

      //resize
      //this.resize.resizing();

      // index service menu
      if (document.querySelectorAll(".index-service-menu__item")[0]) {
        let menuItems = document.querySelectorAll(".index-service-menu__item");
        let thumbItems = document.querySelectorAll(".index-service-thumb__item");
        for (let i = 0; i < menuItems.length; i++) {
          (function (i) {
            menuItems[i].addEventListener("mouseover", function () {
              thumbItems[i].classList.add("show");
            });
            menuItems[i].addEventListener("mouseout", function () {
              for (let j = 0; j < thumbItems.length; j++) {
                thumbItems[j].classList.remove("show");
              }
            });
          })(i);
        }
      }
    });

    // load時
    // ------------------------------
    window.addEventListener("load", () => {
      this.loadedFlg = true;
      this.openingManager.loaded();

      //OP開始
      if (this.openingManager.flg) {
        this.openingManager.startLoader(); //this.openingManager.startOpening();
      } else {
        window.setTimeout(() => {
          this.body.classList.add("loaded");
        }, 200);
      }

      window.setTimeout(() => {
        this.scrChecker.onUpdate();
      }, 300);
    });

    window.addEventListener("pageshow", () => {
      this.dmenu.browserback();
      this.scrChecker.onUpdate();
    });
  }
}
