import { Env } from "./env.js";
import { Urlparam } from "./urlparam.js"; // URLパラメータ
import Vivus from "vivus";

export class Opening {
  constructor() {
    this.body = {};
    this.html = {};
    this.env = {};
    this.paramAncher = {};
    this.flg = false;

    this.loaderTimeBase = 0;
    this.loaderTime = 0;
    this.loadedFlg = false;
    this.timerLoadCheck = {};
  }

  loaded() {
    this.loadedFlg = true;
  }

  init() {
    this.body = document.getElementsByTagName("body")[0];
    this.html = document.getElementsByTagName("html")[0];

    // 環境
    this.env = new Env();
    this.env.detectEnv();

    // URLパラメータ
    this.paramAncher = new Urlparam();
    this.paramAncher.getParam("op", location.href);

    if (this.body.classList.contains("index")) {
      if (this.paramAncher.param == 0) {
        // Openingなし
        //document.getElementById("cover-basic").classList.add("show");
        window.setTimeout(() => {
          this.body.classList.add("index-opening-mode-finished");
        }, 300);
      } else {
        this.flg = true; //this.startOpening();
      }
    }
  }

  startLoader() {
    //console.log('show-loader');
    this.loaderTimeBase = new Date().getTime();
    this.timeCheckLoaded();
  }

  timeCheckLoaded() {
    let minWaitTime = 0;
    if (this.flg) {
      minWaitTime = 1000;
    }
    //loading完了かつminWaitTimeミリ秒以上経過
    this.timerLoadCheck = setInterval(() => {
      this.loaderTime = new Date().getTime();
      let waitedTime = this.loaderTime - this.loaderTimeBase;
      //console.log("check: loadingWaitedTime=" + waitedTime);

      if (this.loadedFlg && waitedTime > minWaitTime) {
        clearTimeout(this.timerLoadCheck);

        //document.getElementById("cover-opening").classList.add("end-loader");

        //console.log('startOp');
        // window.setTimeout(() => {
        //   this.startOpening();
        // }, 600);
        this.body.classList.add("loaded");
        this.startOpening();
      }
    }, 30);
  }

  startOpening() {
    if (this.body.classList.contains("index")) {
      //ロード待ち or ロゴ表示を最低時間だけ表示

      //   window.setTimeout(() => {
      //     console.log("Opening開始");
      //     this.body.classList.add("loaded");
      //   }, 400);

      window.setTimeout(() => {
        //キャッチコピー
        this.body.classList.remove("index-opening-mode-0");
        this.body.classList.add("index-opening-mode-1");
      }, 500);
      window.setTimeout(() => {
        //浮かぶ円
        this.body.classList.add("index-opening-mode-2");
        this.body.classList.add("show-menu");
      }, 1000);
      window.setTimeout(() => {
        this.body.classList.add("index-opening-mode-3");
      }, 1200);

      window.setTimeout(() => {
        this.body.classList.add("index-opening-mode-finished");
      }, 4000);

      //vivus 線画アニメーション durationはframes
      setTimeout(() => {
        new Vivus("circle-1", {
          duration: 70,
          type: "async",
        });
      }, 1000);
      setTimeout(() => {
        new Vivus("circle-2", {
          duration: 60,
          type: "async",
        });
      }, 1100);
      setTimeout(() => {
        new Vivus("circle-3", {
          duration: 60,
          type: "async",
        });
      }, 100);
      setTimeout(() => {
        new Vivus("circle-4", {
          duration: 60,
          type: "async",
        });
        new Vivus("circle-5", {
          duration: 60,
          type: "async",
        });
        new Vivus("circle-6", {
          duration: 60,
          type: "async",
        });
        new Vivus("circle-7", {
          duration: 60,
          type: "async",
        });
      }, 1300);

      //   window.setTimeout(() => {
      //     document.getElementById("cover-opening").classList.add("hide-scene-1");
      //   }, 500);
      //   window.setTimeout(() => {
      //     document.getElementById("cover-opening").classList.add("hide-scene-3");
      //   }, 2200);

      //   let baseTime = 4400;
      //   //index-opening-mode-finished追加
      //   window.setTimeout(() => {
      //     document.getElementById("cover-opening").classList.add("hide-scene-4");
      //   }, baseTime);
      //   window.setTimeout(() => {
      //     document.getElementById("cover-opening").classList.add("hide-scene-5");
      //   }, baseTime + 1200);

      //   //その後に index-opening-mode-finished追加
      //   window.setTimeout(() => {
      //     this.body.classList.add("index-opening-mode-finished");
      //     document
      //       .getElementById("cover-opening")
      //       .classList.add("index-opening-mode-finished");
      //   }, baseTime + 1600);
    }
  }
}
