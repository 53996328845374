export class ScrChecker {
  constructor() {
    this.inviewElems = []; //inviewで効果をつける要素を登録する
    this.parallaxSections = [];
    this.showPos = 0;
    this.thenPosition = 0;
    this.body = {};
    this.html = {};
  }
  setProps(el, props) {
    for (let key in props) {
      el.style.setProperty("--" + key, props[key]);
    }
  }
  clamp(v, min, max) {
    return min > v ? min : max < v ? max : v;
  }
  round(n) {
    return Math.round(n * 10000) / 10000;
  }

  calcElementOffsetY(elem) {
    let container = window;

    let parentElem;
    if (elem.parentElement) {
      parentElem = elem.parentElement;
    }
    let target = parentElem;

    // ページ全体に対するY座標を計算
    let numY = 0;
    do {
      numY += target.offsetTop; //target.offsetTop;はノードの上端に対しての現在の要素の距離
      target = target.offsetParent;
      //console.log(target);
    } while (target && target !== container); //要素のcontainerに対するY座標

    return numY;
  }

  isOpeningPlaying() {
    // オープニングがページ内にあり、再生中かどうか
    let flg = false;
    if (document.querySelectorAll(".index-cover")[0]) {
      if (this.body.classList.contains("index-opening-mode-finished")) {
        flg = false;
      } else {
        flg = true;
      }
    }
    return flg;
  }

  onUpdate() {
    //ウインドウ情報 ie11未対応
    //let windowWidth = window.innerWidth;
    //let windowHeight = window.innerHeight;
    //let windowOffsetY = window.scrollY;

    //ウインドウ情報など ie11対応
    let windowLeft = window.screenLeft || window.screenX || 0;
    let windowTop = window.screenTop || window.screenY || 0;
    let windowWidth = window.innerWidth || document.documentElement.clientWidth || 0;
    let windowHeight = window.innerHeight || document.documentElement.clientHeight || 0;
    let windowScrollY = window.scrollY || window.pageYOffset;
    let windowScrollX = window.scrollX || window.pageXOffset;

    if (windowScrollY > 100) {
      this.body.classList.add("scrolled");
    } else {
      this.body.classList.remove("scrolled");
    }
    if (windowScrollY > 0.5 * windowHeight) {
      this.body.classList.add("scrolled-windowheight-half");
    } else {
      this.body.classList.remove("scrolled-windowheight-half");
    }

    if (windowScrollY > windowHeight) {
      this.body.classList.add("scrolled-windowheight");
    } else {
      this.body.classList.remove("scrolled-windowheight");
    }

    if (windowScrollY > 2 * windowHeight) {
      this.body.classList.add("scrolled-windowheight-x2");
    } else {
      this.body.classList.remove("scrolled-windowheight-x2");
    }
    if (windowScrollY > 3 * windowHeight) {
      this.body.classList.add("scrolled-windowheight-x3");
    } else {
      this.body.classList.remove("scrolled-windowheight-x3");
    }

    // if (windowScrollY > windowHeight * 0.8) {
    //   this.body.classList.add("scrolled-show-index-pager");
    // } else {
    //   this.body.classList.remove("scrolled-show-index-pager");
    // }

    // ===========
    // スクロール方向によりメニューを状態変更
    if (this.thenPosition < document.documentElement.scrollTop && windowScrollY > 0) {
      //下へスクロール
      //console.log(`scroll down`);
      //   this.body.classList.remove("show-menu");
      //   this.body.classList.remove("show-menu-bg");

      this.body.classList.remove("show-menu-bg");
      if (windowScrollY < 200 && this.body.classList.contains("os-ios")) {
        this.body.classList.add("show-menu");
      } else {
        this.body.classList.remove("show-menu");
      }
    } else {
      //上へスクロール
      //console.log(`scroll up`);
      //console.log(this.body);
      this.body.classList.add("show-menu");

      if (this.body.classList.contains("index")) {
        //トップページ
        if (this.body.classList.contains("scrolled-windowheight")) {
          this.body.classList.add("show-menu-bg");
        } else {
          this.body.classList.remove("show-menu-bg");
        }
      } else {
        //中ページはヘッダの高さを見る？
        if (document.querySelectorAll(".js-sub-header")[0] && document.getElementById("menu")) {
          let subHeaderheight = document.querySelectorAll(".js-sub-header")[0].clientHeight;
          let menuHeight = document.getElementById("menu").clientHeight;
          if (subHeaderheight - menuHeight < windowScrollY) {
            this.body.classList.add("show-menu-bg");
          } else {
            this.body.classList.remove("show-menu-bg");
          }
        }
      }
    }

    if (this.body.classList.contains("index")) {
      //トップページ
      //オープニング中はメニューを表示しない
      if (!this.body.classList.contains("index-opening-mode-2")) {
        this.body.classList.remove("show-menu");
        this.body.classList.remove("show-menu-bg");
      }
    }

    //スムーズるクロール時はメニューを表示しない
    if (this.body.classList.contains("is-scrolling")) {
      this.body.classList.remove("show-menu");
      this.body.classList.remove("show-menu-bg");
    }

    this.thenPosition = document.documentElement.scrollTop;

    // ===========
    // トップページのスクロール監視(opening-group, opening-about)
    if (document.getElementById("opening-about")) {
      let scrollBaseY = document.getElementById("opening-about").getBoundingClientRect().top;

      //SPで白い四角の高さを調整（後ろに来ないように）
      let whiteRectHeight = document.getElementById("opening-white-rect").clientHeight; //windowHeight * 0.4 - 40; //最大サイズ
      let transY = 0;
      if (document.getElementById("opening-white-rect")) {
        let elem = document.getElementById("opening-white-rect");
        if (whiteRectHeight > scrollBaseY) {
          transY = this.clamp(whiteRectHeight - scrollBaseY, 0, whiteRectHeight);
        } else {
          transY = 0;
        }
        let props = {
          transy: Math.round(transY),
        };
        this.setProps(elem, props);
      }
      //console.log("opening-about", scrollBaseY, "white-rect", whiteRectHeight - scrollBaseY);
    }

    if (document.getElementById("opening-group")) {
      let scrollBaseY = document.getElementById("opening-group").getBoundingClientRect().top;

      // pc
      if (1.2 * windowHeight > scrollBaseY && 1.2 * -1.5 * windowHeight < scrollBaseY) {
        this.body.classList.add("scrolled-opening-group-desktop");
        window.setTimeout(() => {
          this.body.classList.add("scrolled-opening-group-desktop-2");
        }, 100);
      } else {
        this.body.classList.remove("scrolled-opening-group-desktop");
        window.setTimeout(() => {
          this.body.classList.remove("scrolled-opening-group-desktop-2");
        }, 100);
      }

      // moblie
      if (windowHeight > scrollBaseY) {
        this.body.classList.add("scrolled-opening-group-mobile");
      } else {
        //this.body.classList.remove("scrolled-opening-group-mobile");
      }
      if (0.5 * windowHeight > scrollBaseY) {
        this.body.classList.add("scrolled-opening-group-mobile-2");
      } else {
        //this.body.classList.remove("scrolled-opening-group-mobile-2");
      }
    }

    // opの下までスクロールしたらopening隠す
    if (document.getElementById("content-under-opening")) {
      let scrollBaseY = document.getElementById("content-under-opening").getBoundingClientRect().top;
      if (0 > scrollBaseY) {
        this.body.classList.add("scrolled-under-opening");
      } else {
        this.body.classList.remove("scrolled-under-opening");
      }
    }

    // ===========
    // memberスライダー横移動
    if (document.getElementById("vertical-scrolling")) {
      let memberSlider = document.getElementById("vertical-scrolling");

      // スライダー
      let memberSliderContents = document.querySelector(".js-vertical-scroller-sidemove");

      if (windowWidth >= 700 && windowWidth / windowHeight <= 1) {
        //タブレット（縦長で幅700以上）では横スクロールしない
        this.body.classList.add("no-vertical-scrolling");
        memberSliderContents.style = null;
        memberSlider.classList.add("is-tablet-vertical--no-scroll");
      } else {
        this.body.classList.remove("no-vertical-scrolling");
        memberSlider.classList.remove("is-tablet-vertical--no-scroll");

        // スクロール実体
        let memberSliderScroller = document.querySelectorAll(".js-vertical-scroller-container .js-vertical-scroller");
        let scrollerHeight = memberSliderScroller[0].clientHeight;

        // スクロール最大量
        //let scrollMax = memberSliderScroller[0].clientHeight;

        // スクロール計算Y
        let scrollY = memberSliderScroller[0].getBoundingClientRect().top;

        // スクロール計算基準Y
        let baseY = memberSlider.querySelector(".is-sticky").clientHeight;

        // スクロールYのmin/max = stickyされる範囲
        let scrollMax = baseY;
        let scrollMin = baseY - scrollerHeight; // - windowHeight;

        // スクロールYの量を(0からmaxに調整し)割合に変換
        let movePercent = 1 - (1 * (scrollY - scrollMin)) / (scrollMax - scrollMin);
        movePercent = this.clamp(movePercent, 0, 1);

        let pd = 0;
        if (windowWidth >= 1080) {
          pd = 15 + 65;
        } else if (windowWidth >= 700) {
          pd = 0; //tab スクロールせず
        } else {
          pd = 32; //sp
        }

        let moveXmin = 0;
        let moveXmax = memberSliderContents.clientWidth - windowWidth - 2; //増やすと多く移動する
        //let moveXmax = memberSliderContents.clientWidth + 0.5*pd + 1;//増やすと多く移動する
        let transX = Math.round(moveXmax * movePercent);
        transX = this.clamp(transX, moveXmin, moveXmax);

        //console.log(memberSliderContents.clientWidth, movePercent, moveXmax, transX, scrollY, scrollMin, scrollMax);

        let propsw = {
          transx: transX,
        };
        this.setProps(memberSliderContents, propsw);
      }
    }

    // ===========
    // SIDE PAGER 切り替え
    if (document.getElementById("js-side-pager") || document.querySelectorAll(".about-sub-menu")[0]) {
      // 全体
      let sidePos = document.querySelectorAll("#js-side-pager, .about-sub-menu")[0];

      //リンク
      let links = sidePos.querySelectorAll("ul li a");

      // 切り替え要素のid
      let checkSectionsId = [];
      [].forEach.call(sidePos.querySelectorAll("ul li a"), (elem) => {
        const id = elem.getAttribute("href");
        checkSectionsId.push(id.replace(/#/g, ""));
      });
      //console.log(checkSectionsId);

      // 丸リセット
      let nowState = 0;
      [].forEach.call(links, (elem) => {
        elem.classList.remove("selected");
      });

      // 切り替え実行 1
      [].forEach.call(checkSectionsId, (id) => {
        let elem = document.getElementById(id);
        let clientRect = elem.getBoundingClientRect(); // 要素の位置座標
        let y = clientRect.top; //画面の上端から、要素の上端までの距離

        //切り替えタイミング ウインドウ上端から
        let showPos = windowHeight * 0.5;
        // if (windowWidth > 1200) {
        //   showPos = windowHeight * 0.3; //pc
        // }
        //何番目を表示するか
        if (y < showPos) {
          nowState += 1;
        }
      });
      //console.log(nowState); //checkSectionsId[nowState]);

      // 丸 切り替え
      [].forEach.call(links, (elem, index) => {
        if (nowState - 1 == index) {
          elem.classList.add("selected");
        }
      });
    }

    // ===========
    // inview判定

    //showpos
    this.showPos = windowHeight * 0.7;
    if (windowWidth > 980) {
      this.showPos = windowHeight * 0.7; //pc
    }

    //.js-inview に .inview 追加
    if (this.inviewElems.length !== 0) {
      //inviewElems = document.querySelectorAll('.js-inview');//複数
      [].forEach.call(this.inviewElems, (elem) => {
        //console.log(elem.classList);
        let clientRect = elem.getBoundingClientRect(); // 要素の位置座標
        let y = clientRect.top; //画面の上端から、要素の上端までの距離
        //let elemY = window.pageYOffset + clientRect.top;// ページの上端から、要素の上端までの距離

        if (this.showPos > y) {
          if (this.body.classList.contains("loaded")) {
            elem.classList.add("inview");
            // let timerInviewAfter = setTimeout( () => {
            //     elem.classList.add('inview');
            // }, 1000);
          } else {
            elem.classList.add("inview");
          }
        }
      });
    }
  }

  // 初期化
  init() {
    this.body = document.getElementsByTagName("body")[0];
    this.html = document.getElementsByTagName("html")[0];
    this.inviewElems = document.querySelectorAll(".js-inview");
  }
}
