export class Dmenu {
  constructor() {
    this.body = {};
    this.html = {};
    this.menuBtn = {};
    this.linkContainer = {};
    this.checked = false;
    this.overlayElm = {};
    //this.pcBuyBtn = {};
  }

  linkClicked() {
    // サブメニューを閉じる機能 (ドロワーメニュー内のリンクやXボタンなどを押した場合に)
    //this.checked = false;

    this.body.classList.remove("drawer-menu-open");
    this.body.classList.add("drawer-menu-closing");
    this.checked = false;

    //閉じた後
    window.setTimeout(() => {
      this.body.classList.remove("drawer-menu-closing");
      this.body.classList.add("drawer-menu-closed");
    }, 800);
  }

  browserback() {
    this.body.classList.remove("drawer-menu-open");
    this.checked = false;
  }

  init() {
    this.body = document.getElementsByTagName("body")[0];
    this.html = document.getElementsByTagName("html")[0];
    this.menuBtn = document.getElementById("drawer-menu-trigger");
    this.linkContainer = document.getElementById(
      "drawer-menu-scroll-container"
    );

    if (document.getElementById("drawer-menu-trigger")) {
      this.menuBtn.addEventListener("click", () => {
        if (this.body.classList.contains("drawer-menu-open")) {
          //閉じる
          this.body.classList.remove("drawer-menu-open");
          this.body.classList.add("drawer-menu-closing");

          //閉じた後
          window.setTimeout(() => {
            this.body.classList.remove("drawer-menu-closing");
            this.body.classList.add("drawer-menu-closed");
          }, 800);
        } else if (this.body.classList.contains("drawer-menu-closing")) {
          //無効
        } else {
          //開く
          this.body.classList.remove("drawer-menu-closed");
          this.body.classList.add("drawer-menu-open");
        }
      });

      //aタグの場合
      //   this.linkElems = this.linkContainer.querySelectorAll("a:not(.btn-lang)");
      //   [].forEach.call(this.linkElems, (elem) => {
      //     elem.addEventListener("click", () => {
      //       this.linkClicked();
      //     });
      //   });

      //overlayの場合
      //   this.overlayElm = document.getElementById("overlay");
      //   this.overlayElm.addEventListener("click", () => {
      //     console.log("overlay-clicked");
      //     this.linkClicked();
      //   });

      //PC購入ボタン
      //   if (document.querySelectorAll(".js-pulldown-container")[0]) {
      //     this.pcBuyBtn = document.querySelectorAll(".js-pulldown-container")[0];
      //     this.pcBuyBtn.addEventListener("click", () => {
      //       this.pcBuyBtn.classList.toggle("is-pulldown-open");
      //     });
      //   }
    }
  }

  removeListener() {
    // addEventListener が重複して登録される https://qiita.com/yuki153/items/c909c54204eaab6ca1b2
  }
}
