// スムーススクロール
import SmoothScroll from "smooth-scroll";
// https://github.com/cferdinandi/smooth-scroll

// スムーススクロール "window.scroll" polyfill
//import smoothscroll from 'smoothscroll-polyfill';
// https://www.npmjs.com/package/smoothscroll-polyfill
// http://iamdustan.com/smoothscroll/

// "window.scroll" smooth not working on Safari
// https://stackoverflow.com/questions/51731754/window-scroll-smooth-not-working-on-safari
// Window.scroll() https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll

export class Acc {
  constructor() {
    // アコーディオン複数の場合
    this.accGroups = {};
    this.toggleMenus = {};

    //その他
    this.hasAccordion = false;
    //this.accMinHeight = 300;
    //this.scroll = {};
  }

  init() {
    //console.log('acc init');
    if (document.querySelectorAll(".accordion")[0]) {
      this.hasAccordion = true;
      // アコーディオン複数の場合
      this.accGroups = document.querySelectorAll(".accordion");
      [].forEach.call(this.accGroups, (elem) => {
        //console.log(elem);
        //checkbox
        let checkbox = elem.querySelector(".accordion__button");
        // close btn
        let closeBtn = elem.querySelector(".button-circle-minus");
        closeBtn.addEventListener("click", (e) => {
          checkbox.checked = false;
        });
      });

      //scroll
      //this.scroll = new SmoothScroll();
      //this.scroll.init();
    }
  }

  accResize() {}
}
