export class Urlparam {
    constructor() {
        this.param = null;
    }

    getParam(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        //return decodeURIComponent(results[2].replace(/\+/g, " "));
        this.param = decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    getPath(url) {
        return url.split(/[?#]/)[0];
    }

    removeParams() {
        // urlパラメータを削除
        let url = new URL(window.location.href);
        let url2 = url.pathname.replace(/\?.*$/, '');
        history.replaceState('', '', url2);
    }
}